import React, { useContext } from "react";
import css from "styled-jsx/css";
import { ContextApp } from '@lmig/dotcom-aspect-components/ContextApp';
import { IconFacebook, IconLinkedin } from "@lmig/lmds-react/icons";
import { Heading, Disclaimer, Link } from "@lmig/lmds-react";
import useEnvironmentalizeLink from "@lmig/dotcom-aspect-hooks/useEnvironmentalizeLink";
import FooterLinkCcpa from '@lmig/dotcom-aspect-components/CCPAFooterLink';
import PhoneNumber from '@lmig/dotcom-aspect-components/Utility/PhoneNumber';
import Image from '@lmig/dotcom-aspect-components/Utility/Image';
import { formatPhoneNumber } from "@lmig/dotcom-aspect-comparion-helpers/phoneNumber";

export default function Footer() {
  const { styles: headingStyles, className: headingClassName } = css.resolve`
    .lm-Heading {
      color: #f5f5f5;
      margin: 1.5rem 0 0.5rem 0;

      @media screen and (min-width: 60rem) {
        margin-top: 0;
      }
    }
  `;
  const {
    styles: disclaimerStyles,
    className: disclaimerClassName,
  } = css.resolve`
    .lm-Disclaimer {
      margin: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #c0bfc0;
      color: #343741;
    }
  `;
  const { styles: anchorStyles, className: anchorClassName } = css.resolve`
    a,
    a:hover,
    a:focus,
    a:visited,
    .lm-Link,
    .lm-Link:hover,
    .lm-Link:focus,
    .lm-Link:visited {
      color: #f5f5f5;
      display: block;
      font-weight: 400;
      display: inline-block;
    }
  `;
  const { styles: iconStyles, className: iconClassName } = css.resolve`
    svg {
      @media screen and (min-width: 60rem) {
        width: 2rem;
        height: 100%;
      }
    }
  `;
  const { styles: ccpaStyles, className: ccpaClassName } = css.resolve`
    .ccpra{
      display: flex;
      flex-flow: column;
      min-height: 2rem;
      width: 100%;
      margin: 0 auto 1.5rem;
      max-width: 80rem;
      height: auto;
      
      @media screen and (min-width: 60rem) {
        display: block;
      }
    }
    :global(.ccpra .lm-Link.lm-LinkInline.lm-Link--onBlue) {
      text-align: left;
      color: #f5f5f5;
      font-weight: 400;
    }
    :global(.ccpra .footerPipe) {
      display: none;

      @media screen and (min-width: 60rem) {
        display: inline-block;
        margin: 0 0.75rem;
      }
    }
  `;

  const { phones } = useContext(ContextApp);
  const phoneNumber = formatPhoneNumber(phones?.drc?.number);
  const agentLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance-agent" });
  const whyUseAnAgentLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/resources/why-use-an-insurance-agent" });
  const aboutUsLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/about-us" });
  const careersLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/careers" });
  const ourProductsLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance" });
  const insurancePartnersLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/insurance-partners" });
  const partnershipProgramsLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/partnership-programs" });
  const insuranceAdviceLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/resources" });
  const serviceLink = useEnvironmentalizeLink({ domain: "LM_COM", link: "/customer-support" });
  const claimsLink = useEnvironmentalizeLink({ domain: "LM_COM", link: "/customer-support/claims-support" });
  const privacyPolicyLink = useEnvironmentalizeLink({ domain: "COMPARION", link: "/privacy-policy" });
  const securityPolicyLink = useEnvironmentalizeLink({ domain: "LMG", link: "/about-lm/our-company/security-policy" });
  const webPolicyLink = useEnvironmentalizeLink({ domain: "LMG", link: "/about-lm/our-company/web-accessibility-policy" });
  const termsLink = useEnvironmentalizeLink({ domain: "LMG", link: "/about-lm/our-company/terms-and-conditions" });
  const fraudLink = useEnvironmentalizeLink({ domain: "LMG", link: "/about-lm/our-company/fraud-protection-tips" });
  const equalLink = useEnvironmentalizeLink({ domain: "LMG", link: "/about-lm/our-company/equal-employment-opportunity-and-anti-discrimination-policy" });

  return (
    <>
      <footer id="mainFooter">
        <div className="wrapper">
          <div className="main">
            <div className="top">
              <div className="imageWrapper">
                <Image imageDomain="COMPARION" deferLoad>
                  <img
                    alt="Comparion Insurance Logo"
                    src="/images/comparion_logo_white.svg"
                    height="30px"
                    width="120px"
                  />
                </Image>
              </div>
              <div className="socialLinks">
                <a
                  href="https://www.facebook.com/ComparionInsuranceAgency"
                  aria-label="visit us on facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-action="action url"
                  data-icon="icon-facebook"
                >
                  <IconFacebook
                    size="24"
                    color="inverse"
                    className={iconClassName}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/comparion-insurance-agency"
                  aria-label="visit us on linkedin"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-action="action url"
                  data-icon="icon-linkedin"
                >
                  <IconLinkedin
                    size="24"
                    color="inverse"
                    className={iconClassName}
                  />
                </a>
              </div>
            </div>
            <div className="block">
              <Heading type="h4-bold" tag="div" className={headingClassName}>
                Get a quote today
              </Heading>
              <div>
                <PhoneNumber tel={phoneNumber || "800-835-0894"}>
                  <Link
                    aria-label="call to get a quote today"
                    caretPosition="none"
                    onBackground="blue"
                    data-action="action telephone"
                    className={anchorClassName}
                  />
                </PhoneNumber>
              </div>
              <div>
                <Link
                  href={agentLink}
                  aria-label="find an agent"
                  caretPosition="none"
                  onBackground="blue"
                  className={anchorClassName}
                >
                  Find a local agent
                </Link>
              </div>
              <Link
                href={whyUseAnAgentLink}
                aria-label="why use an insurance agent"
                caretPosition="none"
                onBackground="blue"
                className={anchorClassName}
              >
                Why quote with an agent
              </Link>
            </div>
            <div className="block quicklinks">
              <Heading type="h4-bold" tag="div" className={headingClassName}>
                Quick Links
              </Heading>
              <nav aria-label="Primary" role="navigation" className="links">
                <ul>
                  <li>
                    <Link
                      href={aboutUsLink}
                      aria-label="about comparion"
                      caretPosition="none"
                      onBackground="blue"
                      className={anchorClassName}
                    >About Us</Link>
                  </li>
                  <li>
                    <Link
                      href={careersLink}
                      aria-label="careers at comparion"
                      caretPosition="none"
                      onBackground="blue"
                      className={anchorClassName}
                    >Careers</Link>
                  </li>
                  <li>
                    <Link
                      href={ourProductsLink}
                      aria-label="shop insurance"
                      caretPosition="none"
                      onBackground="blue"
                      className={anchorClassName}
                    >
                      Shop insurance
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={insurancePartnersLink}
                      aria-label="insurance partners"
                      caretPosition="none"
                      onBackground="blue"
                      className={anchorClassName}
                    >
                      Insurance Partners
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={insuranceAdviceLink}
                      aria-label="insurance advice"
                      caretPosition="none"
                      onBackground="blue"
                      className={anchorClassName}
                    >
                      Insurance advice
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={partnershipProgramsLink}
                      aria-label="partnership programs"
                      caretPosition="none"
                      onBackground="blue"
                      className={anchorClassName}
                    >
                      Partnership Programs
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={serviceLink}
                      aria-label="liberty mutual service"
                      caretPosition="none"
                      onBackground="blue"
                      className={anchorClassName}
                    >Liberty Mutual Service</Link>
                  </li>
                  <li>
                    <Link
                      href={claimsLink}
                      aria-label="liberty mutual claims"
                      caretPosition="none"
                      onBackground="blue"
                      className={anchorClassName}
                    >Liberty Mutual Claims</Link>
                  </li>
                </ul>
              </nav>

            </div>
          </div>
          <div className="secondary">
            <nav aria-label="Secondary" role="navigation" className="links">
              <span className="link">
                <Link
                  href={privacyPolicyLink}
                  aria-label="privacy policy"
                  caretPosition="none"
                  onBackground="blue"
                  className={anchorClassName}
                >Privacy Policy</Link>
                <span className="footerPipe"> | </span>
              </span>
              <span className="link">
                <Link
                  href={securityPolicyLink}
                  aria-label="security policy"
                  caretPosition="none"
                  onBackground="blue"
                  className={anchorClassName}
                >Security Policy</Link>
                <span className="footerPipe"> | </span>
              </span>
              <span className="link">
                <Link
                  href={webPolicyLink}
                  aria-label="web accessibility policy"
                  caretPosition="none"
                  onBackground="blue"
                  className={anchorClassName}
                >Web Accessibility Policy</Link>
                <span className="footerPipe"> | </span>
              </span>
              <span className="link">
                <Link
                  href={termsLink}
                  aria-label="terms and conditions"
                  caretPosition="none"
                  onBackground="blue"
                  className={anchorClassName}
                >Terms and Conditions</Link>
                <span className="footerPipe"> | </span>
              </span>
              <span className="link">
                <Link
                  href={fraudLink}
                  aria-label="fraud protection tips"
                  caretPosition="none"
                  onBackground="blue"
                  className={anchorClassName}
                >Fraud Protection</Link>
              </span>
            </nav>
          </div>
          <FooterLinkCcpa className={`ccpra ${ccpaClassName}`} />
        </div>
      </footer>
      <div className="disclaimerFooter">
        <div className="wrapper">
          <Disclaimer className={disclaimerClassName}>
            ©{new Date().getFullYear()} Comparion Insurance Agency, A Liberty Mutual Company - 175 Berkeley Street, Boston, MA 02116.
            The materials herein are for informational purposes only.
            All statements made are subject to provisions, exclusions, conditions, and limitations of the applicable insurance policy.
            Coverages and features not available in all states.
            Eligibility is subject to meeting applicable underwriting criteria.
            Learn more about our privacy policy at&nbsp;
            <Link
              href={privacyPolicyLink}
              aria-label="privacy policy"
              caretPosition="none"
              style={{ color: "#1a1446" }}
            >libertymutual.com/privacy</Link>.
          </Disclaimer>
          <Disclaimer className={disclaimerClassName}>
            {`Comparion is an `}
            <Link
              href={equalLink}
              aria-label="equal employment opportunity policy"
              caretPosition="none"
              style={{ color: "#1a1446" }}
            >
              Equal Opportunity Employer
            </Link>
            {` and an Equal Housing Insurer.`}
          </Disclaimer>
        </div>
      </div>
      {headingStyles}
      {disclaimerStyles}
      {anchorStyles}
      {iconStyles}
      {ccpaStyles}
      <style jsx>{`
        @import './global-styles/_tokens.scss';

        .disclaimerFooter {
          margin-bottom: 5rem;
          overflow: hidden;
          width: 100%;
          z-index: 1;
          position: relative;
          background-color: #FFFFFF;

          @media screen and (min-width: $lm-breakpoint-md-min) {
            margin-bottom: 0;
          }
          .wrapper {
            @media screen and (min-width: $lm-breakpoint-md-min) {
              padding: 0 3rem;
            }
          }
        }
        footer {
          overflow: hidden;
          width: 100%;
          background-color: #1a1446;
          color: #f5f5f5;
          padding: 0 1rem;
          z-index: 1;
          position: relative;

          .wrapper {
            display: flex;
            flex-flow: column;
            max-width: 78rem;

            @media screen and (min-width: $lm-breakpoint-md-min) {
              padding: 0 3rem;
            }
          }
          .main {
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            justify-content: flex-start;

            @media screen and (min-width: $lm-breakpoint-lg-min) {
              padding-top: 2rem;
              flex-flow: row;
            }

            .block{
              width: 100%;

              @media screen and (min-width: $lm-breakpoint-sm-min) {
                width: 50%;
              }
            }
          }
          .imageWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media screen and (min-width: $lm-breakpoint-lg-min) {
              img {
                height: 50px;
                width: 200px;
              }
            }
          }
          .top{
            display: flex;
            flex-flow: row;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #c0bfc0;
            padding: 1rem 0;

            @media screen and (min-width: $lm-breakpoint-lg-min) {
              flex-flow: column;
              padding: 0;
              border-bottom: none;
              align-items: flex-start;
              justify-content: flex-start;
              min-width: 20rem;
              max-width: 33%;
            }
          }
          .quicklinks {
            margin-right: -3%;

            nav ul {
              @media screen and (min-width: $lm-breakpoint-lg-min) {
                max-height: 14rem;
              }
              @media screen and (min-width: 68rem) {
                display: flex;
                flex-flow: column wrap;
                max-height: 8rem;

                li {
                  max-width: 50%;
                }
              }
            }
          }
          .secondary{
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            width: 100%;
            border-top: 1px solid #c0bfc0;
            padding-top: 1rem;
            margin-bottom: 1.5rem;
            margin-top: 1rem;

            span { 
              display: inline-block;

              &.link {
                display: inline-block;
              }
              &.footerPipe {
                padding: 0 1em;
              }
            }
          }
          .socialLinks {
            a {
              display: inline-block;
              margin-right: 1rem;

              @media screen and (min-width: $lm-breakpoint-lg-min) {
                margin-right: 1.5rem;
                margin-top: 1.5rem;
              }
              :last-child {
                margin-right: 0;
              }
            }
          }
          ul {
            margin: 0;
            padding: 0;

            li {
              display: block;
              margin: 0;
              padding: 0;
            }
          }
        }
      `}</style>
    </>
  );
}
